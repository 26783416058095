import { useTranslation } from "react-i18next";
import oq from "../../assets/images/angren.jpeg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex justify-center items-center px-[100px] gap-8 h-[400px]">
        <div className="w-[600px]">
          <img src={oq} alt="" />
        </div>
        <div className="w-[865px] h-[170px]">
          <h4 className="font-[700] mb-[10px] mt-[-50px] text-[36px] text-#121221">
            {t("Angren shahri haqida")}
          </h4>
          <p>
            <strong className="text-[20px]">Angren shahri</strong>—Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Varius sit amet mattis
            vulputate enim nulla aliquet porttitor. Tortor at risus viverra
            adipiscing at in. Orci dapibus ultrices in iaculis nunc sed augue
            lacus viverra. Malesuada pellentesque elit eget gravida cum sociis
            natoque penatibus. Pellentesque elit eget gravida cum. Vitae et leo
            duis ut diam quam nulla. Fermentum iaculis eu non diam phasellus
            vestibulum. Gravida in fermentum et sollicitudin.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegionInfo;
